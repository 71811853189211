<template>
  <div>
    <Upload v-if="$authFunsProxyNoRoute['performance.wages.manage.index-uploadDetail']" style="flex: 1;" type="drag" action="/" paste :show-upload-list="false" :format="['xls', 'xlsx']" accept=".xls,.xlsx" :before-upload="beforeUpload" :on-format-error="onFormatError">
      <div style="padding: 50px 0">
        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p>点击或者拖拽文件到此处上传</p>
      </div>
    </Upload>
    <div v-else>暂无权限</div>
    <Modal v-model="status.batch" width="80vw" :mask-closable="false">
      <div slot="header" style="color:#f60;">
        <Icon type="ios-information-circle"></Icon>
        <span>导入异常预览</span>
        <Tag color="error" style="margin-left: 10px;">
          {{ batchList.filter(v => v.isError).length }}条异常
        </Tag>
        <Tag color="error" style="margin-left: 10px;">
          {{ batchList.filter(v => v.isIgnore).length }}条已忽略
        </Tag>
      </div>
      <Table @on-selection-change="onSelectionChange" ref="table" size="small" :data="batchList" :columns="columns" :height="500">
        <template slot-scope="{ row, index }" slot="action">
          <template v-if="row.isError">
            <template v-if="row.isIgnore">
              <Button size="small" disabled>已忽略</Button>
            </template>
            <template v-else>
              <Poptip style="margin-right: 10px;" transfer confirm @on-ok="addWorker(row, index)" title="确定新增吗？" v-if="row.noWorker">
                <Button size="small" type="primary">新增</Button>
              </Poptip>
              <Poptip transfer confirm @on-ok="ignore(row, index)" title="确定忽略吗？">
                <Button size="small" type="warning">忽略</Button>
              </Poptip>
            </template>
          </template>
        </template>
      </Table>
      <div slot="footer" style="display: flex; align-items: center; justify-content: space-between;">
        <div>
          <Button @click="sectionAll" :disabled="!batchList.length">全选</Button>
          <Button @click="unSection" :disabled="!batchList.length">不选</Button>
          <Button @click="allIgnore" :disabled="!sectionList.filter(v => v.isError && !v.isIgnore).length">批量忽略</Button>
          <Button @click="allAdd" :disabled="!sectionList.filter(v => v.isError && v.noWorker).length">批量新增</Button>
        </div>
        <div>
          <Button @click="status.batch = false">取消</Button>
          <Button :loading="loading.confirm" @click="batch()" type="primary">确定</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { request } from '@/api'

export default {
  data () {
    return {
      batchList: [],
      dataList: [],
      status: {
        batch: false
      },
      loading: {
        confirm: false
      },
      sectionList: [],
      columns: [
        { type: 'selection', width: 80 },
        { title: '员工', key: 'name' },
        { title: '工资代码', key: 'code' },
        { title: '异常说明', key: 'error' },
        { title: '操作', key: 'action', slot: 'action' },
      ]
    }
  },
  methods: {
    sectionAll () {
      this.$refs.table.selectAll(true)
    },
    unSection () {
      this.$refs.table.selectAll(false)
    },
    allIgnore () {
      this.sectionList.forEach(item => {
        const index = this.batchList.findIndex(v => v.isError && v.code === item.code && v.name === item.name)
        if (index > -1) {
          this.ignore(item, index)
        }
      })
    },
    async allAdd () {
      this.$Spin.show()
      for (let item of this.sectionList.filter(v => v.isError && v.noWorker)) {
        try {
          await request('/performance/wage_worker', 'post', {
            code: item.code,
            name: item.name
          })
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(() => this.batch(this.batchList, true), 300)
      this.$Spin.hide()
    },
    onSelectionChange (dataList) {
      this.sectionList = dataList
    },
    async addWorker (row) {
      this.$Spin.show()
      try {
        await request('/performance/wage_worker', 'post', {
          code: row.code,
          name: row.name
        })
        this.$Spin.hide()
        this.$Message.success('已添加')
        setTimeout(() => this.batch(this.batchList, true), 300)
      } catch (e) {
        this.$Spin.hide()
        this.$Modal.error({ title: '操作失败', content: e.message })
      }
    },
    beforeUpload (file) {
      this.upload(file)      
      return false
    },
    onFormatError () {
      this.$Modal.error({ title: '不支持的文件类型', content: '请上传excel文件' })
    },
    getMonthConfig () {
      return request('/performance/wage_month/worker_config/' + this.$route.params.monthData.id, 'get')
    },
    ignore (item, index) {
      item.isIgnore = true
      this.batchList.splice(index, 1, item)
    },
    async upload (file) {
      this.$Spin.show()
      try {
        this.batchList = []
        const buffer = await file.arrayBuffer()
        const workbook = XLSX.read(buffer, {
          type: 'array',
          cellDates: true
        })
        console.log(workbook)

        let config = await this.getMonthConfig()
        const configColumns = config.map(v => v.valueLabel)
        console.log(configColumns)

        let newColumns = []
        let sheetJson = {}
        workbook.SheetNames.forEach(sheetName => {
          const type = {'应发': 'yf', '应扣': 'yk'}[sheetName.trim()];
          if (!type) {
            return
          }

          const baseData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            defval: ''
          }).filter(v => v['姓名'] && v['代码'])

          if (!baseData.length) { return }

          const columns = []
          Object.keys(baseData[0]).forEach(col => {
            if (baseData.some(v => v[col] && String(v[col]).trim() !== '')) {
              columns.push(col)
            }
          })
          
          const data = sheetJson[type] = baseData
          if (data.length) {
            columns.forEach((valueLabel, colIndex) => {
              if (valueLabel.indexOf('__EMPTY') > -1) {
                throw new Error('检测到表【' + sheetName + '】中，第【' + (colIndex + 1) + '列】标题为空，请补全标题后重试。')
              }
              if (!['代码', '姓名'].includes(valueLabel) && !configColumns.includes(valueLabel)) {
                newColumns.push({ type, valueLabel })
              }
            })
          }
        })

        console.log({sheetJson, newColumns})
        if (newColumns.length) {
          await request('/performance/wage_month/worker_config/init/' + this.$route.params.monthData.id, 'post', newColumns)
          config = await this.getMonthConfig()
        }

        let dataList = []
        for (let sheetType in sheetJson) {
          for (let row of sheetJson[sheetType]) {
            let itemIndex = dataList.findIndex(v => v.code === row['代码'] && v.name === row['姓名'])
            if (itemIndex === -1) {
              itemIndex = dataList.length
              dataList.push({})
            }
            config.forEach(column => {
              if (row[column.valueLabel] !== undefined) {
                dataList[itemIndex][column.valueKey] = row[column.valueLabel]
              }
            })
            if ((!dataList[itemIndex].code || !dataList[itemIndex].name) && row['姓名'] && row['代码']) {
              dataList[itemIndex].name = row['姓名']
              dataList[itemIndex].code = row['代码']
            }
          }
        }
        await this.batch(dataList)
      } catch (e) {
        console.log(e)
        this.$Modal.error({
          title: '操作失败',
          content: e.message
        })
      } finally {
        this.$Spin.hide()
      }
    },
    async batch (dataList, force = false) {
      this.sectionList = []
      this.$refs.table.selectAll(false)
      try {
        if (dataList) {
          this.batchList = dataList
        } else {
          dataList = this.batchList
        }

        if (!force && this.batchList.filter(v => v.isError && !v.isIgnore).length) {
          return this.$Modal.error({
            title: '操作失败',
            content: '请先处理或忽略所有异常条目'
          })
        }

        this.loading.confirm = true
        const batchResult = await request('/performance/wage_month_worker/batch', 'post', {
          wageMonthId: this.$route.params.monthData.id,
          dataList,
          checkOmit: 1
        })
        
        if (batchResult.success === 1) {
          this.$Message.success('导入成功')
          this.$emit('success')
        } else {
          this.$Message.warning('导入失败')
          this.batchList = batchResult.dataList.sort((a, b) => {
            if (a.isError === true && a.isIgnore === false) {
              return -1
            } else if (b.isError === true && b.isIgnore === false) {
              return 1
            } else {
              return 0
            }
          })
          this.status.batch = true
        }
      } catch (e) {
        console.log(e)
        this.$Modal.error({
          title: '操作失败',
          content: e.message
        })
      }
      this.loading.confirm = false
    }
  }
}
</script>