export function getEmptyMonthItem (month) {
  const spl = month.split('-')
  return {
    id: null,
    month: month,
    status: 'wait',
    type: 'compute',
    remark: '',
    web: {
      year: Number(spl[0]),
      month: Number(spl[1])
    }
  }
}

export function detailTableSum ({ columns, data }) {
  const sums = {}
  columns.forEach((column, index) => {
    const key = column.key
    if (index === 0) {
      sums[key] = { key, value: '合计' }
      return
    } else if (index === 1) {
      sums[key] = { key, value: data.length + ' 人' }
      return
    }
    const values = data.map(item => Number(item[key]))
    const v = values.reduce((prev, curr) => {
      const value = Number(curr)
      if (!isNaN(value)) {
          return prev + curr
      } else {
          return prev
      }
    }, 0)
    sums[key] = { key, value: Number(v.toFixed(2)) }
  })
  return sums
}
